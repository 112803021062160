import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
import Message from '../Component/Message'
import Birthday from'../Component/Birthday'
import News from'../Component/News'
import Events from '../Component/Events'
import HomeGallery from '../Component/HomeGallery'
import HomeVideoGallery from'../Component/HomeVideoGallery'
import HomeToppers from'../Component/HomeToppers'
import HomeToppersXii from'../Component/HomeToppersXii'
import Highlights from'../Component/Highlights'
import HomeSlider from'../Component/HomeSlider'
import ErpLinks from'../Component/ErpLinks'
import Popup from'../Component/Popup'
const Home = () => {
  return (
    <>
    <Popup/>
    <ErpLinks/>
<Header/>
     <div className="container-fluid span3 wow bounceInDown center" data-wow-delay="1s"> 
  <div className="row"> 
   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 notice-color"> 
   <div className="notice-sec"> 
   <div className="row align-content-center notice-sec-details"> 
   <div className="col-12 col-sm-12 col-lg-12"> 
   <div className="card p-2"> <h1> Latest @ Xavier's  <Link to="Highlights" className="btn btn-success"><i className="bi bi-chevron-double-right"></i></Link></h1> </div> </div> 
     <Highlights/>
   </div> </div> </div> 
    <HomeSlider/>
  </div>
   </div> 
 <div className="slider-bottom span3 wow bounceInDown center" data-wow-delay="1s"> 
   <div className="container"> 
  <div className="row"> 
   <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/vision.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>Vision</h5> <Link to="Vision" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> 
 <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/mission.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>Mission</h5> <Link to="Mission" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> 
 <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/newsletter.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>Newsletter</h5> <Link to="Newsletter" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> 
 <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magazine.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>Magazines</h5> <Link to="Magazine" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> 
 <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/acheivement.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>Achievements</h5> <Link to="Achievements" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> 
 <div className="col"> 
   <div className="card"> 
   <div className="backgroundEffect"></div> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/panorama.png" className="card-img-top" alt="St. Xaviers Bathinda" /> 
 <div className="card-body"> <h5>360&#176; View</h5> <Link to="VirtualTour" className="arrow"><i className="bi bi-arrow-right-square"></i></Link> </div> </div> </div> </div> </div> </div> 
 <div className="about-sec mt-5 mb-5 span3 wow bounceInDown center" data-wow-delay="1s"> 
  <div className="row"> 
   <div className="col-lg-7 ms-xl-auto about-sec-data"> 
  <div className="row"> 
   <div className="clearfix"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/about.jpg" className="col-md-6 float-xxl-end float-xl-auto mb-3" alt="St. Xaviers Bathinda" /> <h5>About, St. Xavier's Bathinda</h5> <p>St. Xavier's School, Bathinda is an affiliate of the registered Society of Pilar, Punjab - Haryana, a branch of the Society of Pilar, GOA. The latter runs a number of other social activities like dispensaries, grihini schools, orphanages, balwadis etc. St. Xavier's School, Bathinda was opened in 1983, as a response to the pressing educational needs of the town of Bathinda and the surrounding villages. Originally an all-boys school, it opened its portals to girls in April 1990. The aim of the school is not only to prepare the students academically, but also to cater to the all-round development of the child, especially its moral and intellectual qualities. In this way, the school strives to draw out the best from each individual and enable its pupil to blossom into mature citizens of our country. The school is owned and managed by a religious group, which is a legitimate right provided for minority communities in our Constitution.</p> <Link to="AboutSchool"><button name="data" type="button" className="learn-more">Read More</button></Link> </div> </div> </div> 
 <div className="col-lg-5 ms-xl-auto about-sec-patron"> 
  <div className="row"> 
   <div className="clearfix"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/st_francis.png" className="col-md-6 float-xxl-end float-xl-auto mb-3" alt="St. Xaviers Bathinda" /> <h5>Our Patron</h5> <p>St. Francis Xavier who was born in Spain in 1506 to a noble family, was influenced by Ignatious Loyola. Ignatious Loyola who started the Society of Jesus, recruited Francis Xavier into this order.<br />St. Francis Xavier who landed in Goa during 1542 specifically to stem the rot amongst the faithful, in a short period of time caused a major awakening. He was instrumental in building many churches and institutions in Goa and elsewhere. He travelled across Malacca, China and Japan.</p> <Link to="OurPatron"><button name="data" type="button" className="learn-more">Read More</button></Link> </div> </div> </div> </div> </div>
    <Message></Message>
    <div className="important-links span3 wow bounceInDown center" data-wow-delay="1s">
  <div className="container">
    <div className="row">
      <h1 className="mb-4 mt-5 text-center">Significant bridges</h1>
      <div className="col-12 col-lg-3">
        <div className="card bg-dark text-white"> 
            <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memory.jpg" className="card-img" alt="St. Xaviers Bathinda" />
          <div className="card-img-overlay">
            <h5 className="card-title">Memories</h5>
            <p className="card-text"><Link to="Memories">View</Link></p>
          </div>
        </div>
        <div className="card bg-dark text-white"> 
            <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.jpg" className="card-img" alt="St. Xaviers Bathinda" />
          <div className="card-img-overlay">
            <h5 className="card-title">Circular</h5>
            <p className="card-text"><Link to="Circular">View</Link></p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="row">
          <div className="col-12">
            <div className="card bg-dark text-white"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magazine.jpg" className="card-img" alt="St. Xaviers Bathinda" />
              <div className="card-img-overlay">
                <h5 className="card-title">Magazine</h5>
                <p className="card-text"><Link to="Magazine">View</Link></p>
              </div>
            </div>
          </div>
          <div className="col">
          
            <div className="card bg-dark text-white"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/speech.jpg" className="card-img" alt="St. Xaviers Bathinda" />
              <div className="card-img-overlay">
                <h5 className="card-title"> Parent Testimonials </h5>
                <p className="card-text"><Link to="ParentTestimonial">View</Link></p>
              </div>
            </div>
            
          </div>
          <div className="col">
          
            <div className="card bg-dark text-white"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/booklist.jpg" className="card-img" alt="St. Xaviers Bathinda" />
              <div className="card-img-overlay">
                <h5 className="card-title">Alumni Testimonials</h5>
                <p className="card-text"><Link to="AlumniTestimonials">View</Link></p>
              </div>
            </div>
      
          </div>
        </div>
      </div>
      <div className="col-12  col-lg-3">
        <div className="card bg-dark text-white"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/newsletter.jpg" className="card-img large" alt="St. Xaviers Bathinda" />
          <div className="card-img-overlay">
            <h5 className="card-title">Newsletter</h5>
            <p className="card-text"><Link to="Newsletter">View</Link></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="container-fluid mt-5 news span3 wow bounceInDown center" data-wow-delay="1s">
  <div className="row p-5">
    <div className="col-12 col-xl-7 events-sec">
      <h1>Upshot of Activities <Link to="News" className="btn btn-success"><i className="bi bi-chevron-double-right"></i></Link></h1>
        <News/>
    </div>
<div className="col-12 col-xl-5 birthday-sec">
<h1>Happy Birthday</h1>
  <Birthday/>
</div>
</div>
</div>
<div className="container-fluid gallery span3 wow bounceInDown center" data-wow-delay="1s">
  <div className="row mt-5 p-5">
    <div className="col-12 col-lg-12 col-xl-4 col-xxl-5">
      <h1>Upcoming Events </h1>
      <p>The mind is quiet only when it is not caught in thought, which is ... or when it is bound to the pattern of its own experience, memories.</p>
        <Link to="Events"><button name="data" type="button" className="learn-more">View All</button></Link>
    </div>
    <div className="col-12 col-lg-12 col-xl-8 col-xxl-7">
       <Events/>
    </div>
  </div>
</div>
<div className="container topopers-sec mt-4 mb-5 span3 wow bounceInDown center" data-wow-delay="1s">
<div className="row">
  <div className="col-12 col-xl-6 col-xxl-6">
    <h1 className="mb-4">Toppers X</h1>
       <div className="row">
           <HomeToppers/>
           <center> <Link to="Toppers"><button name="data" type="button" className="learn-more">View All</button> </Link></center>
       </div>  
  </div>
  <div className="col-12 col-xl-6 col-xxl-6">
    <h1 className="mb-4">Toppers XII</h1>
       <div className="row">
           <HomeToppersXii/>
           <center> <Link to="ToppersXii"><button name="data" type="button" className="learn-more">View All</button> </Link></center>
       </div>  
  </div>
</div>
</div>
<div className="container-fluid gallery-new">
<center><h1>Our Gallery  <Link to="PhotoGallery" className="btn btn-success"><i className="bi bi-chevron-double-right"></i></Link></h1></center>
<div className="row">
  <HomeGallery/>
  <HomeVideoGallery/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Home
