import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import BulletinSidebar from'./BulletinSidebar'
import {SlideshowLightbox} from 'lightbox.js-react'
import { Link } from 'react-router-dom'
const Memories = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Memories</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery <i className="bi bi-arrow-right"></i>  Memories</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 memories_sec">
<div className="row">
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_1.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_2.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_3.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_4.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_5.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_6.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_7.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_8.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_9.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_10.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_11.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_12.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_13.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_14.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_15.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_16.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
<div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/memories_17.jpg" className="img-fluid" alt="St. Xavier School"/></SlideshowLightbox></center></article></div>
</div>
<div className="clr"></div>
</div>
<BulletinSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Memories
