import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
import NonTeachingStaff from'./NonTeachingStaff'
import TeachingStaff from'./TeachingStaff'
const Faculty = () => {
   return (
    <>
<Header/>   <div className="inner_slider">
   <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Faculty</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Faculty</span></h5>
   </div>
   </div>
   <div className="innerpage">
   <div className="row inner_cont">
       <div className="col-lg-9 col-md-8 col-sm-12 col-12">
       <div className="tabs1 message_inner">
         <div className="tab1">
           <input type="radio" id="tab-1" name="tab-group-1" checked/>
           <label for="tab-1">Teaching Staff</label>
           <div className="content">
       <div className="innertable">
       <table width="100%">
       
       <tr>
       <th>S. No.</th>
       <th>Name of the Teacher</th>
       <th>Designation</th>
       <th>Qualification</th>
       </tr>
       
        
       
       <TeachingStaff/>
       
       </table>
       </div>
       <div className="clr20"></div>
          </div>
         </div>
         <div className="tab1">
           <input type="radio" id="tab-2" name="tab-group-1"/>
           <label for="tab-2">Non-Teaching Staff</label>
           <div className="content">
       <div className="innertable">
       <table width="100%">
       <tbody>
       <tr>
       <th>S. No.</th>
       <th>Employee Name</th>
       <th>Designation</th>
       <th>Qualification</th>
       </tr>
       
         <NonTeachingStaff/>
       
       
       
       </tbody>
       </table>
       </div>
       <div className="clr20"></div>
         </div>
         </div>
       
       </div>
       
       <div className="clr"></div>
       </div>
   <AboutSidebar/>
   </div>
   </div>
 <Footer/>
  </>
  )
}

export default Faculty
