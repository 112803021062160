import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const Infrastructure = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Infrastructure</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Infrastructure</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-12 col-sm-12 col-12 infra_sec">
   <div className="verticaltabs">
   <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
     <button className="nav-link active" id="v-pills-bio-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bio" type="button" role="tab" aria-controls="v-pills-bio" aria-selected="true">Biology Lab</button>
     <button className="nav-link" id="v-pills-che-tab" data-bs-toggle="pill" data-bs-target="#v-pills-che" type="button" role="tab" aria-controls="v-pills-che" aria-selected="false">Chemistry Lab</button>
     <button className="nav-link" id="v-pills-phy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-phy" type="button" role="tab" aria-controls="v-pills-phy" aria-selected="false">Physics Lab</button>
     <button className="nav-link" id="v-pills-Library-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Library" type="button" role="tab" aria-controls="v-pills-Library" aria-selected="false">Library</button>
     <button className="nav-link" id="v-pills-shooting-tab" data-bs-toggle="pill" data-bs-target="#v-pills-shooting" type="button" role="tab" aria-controls="v-pills-shooting" aria-selected="false">Shooting Range</button>
     <button className="nav-link" id="v-pills-basket-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basket" type="button" role="tab" aria-controls="v-pills-basket" aria-selected="false">Basketball Court</button>
     <button className="nav-link" id="v-pills-acti-tab" data-bs-toggle="pill" data-bs-target="#v-pills-acti" type="button" role="tab" aria-controls="v-pills-acti" aria-selected="false">Activity Hall</button>
     <button className="nav-link" id="v-pills-Building-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Building" type="button" role="tab" aria-controls="v-pills-Building" aria-selected="false">Building</button>
     <button className="nav-link" id="v-pills-danceroom-tab" data-bs-toggle="pill" data-bs-target="#v-pills-danceroom" type="button" role="tab" aria-controls="v-pills-danceroom" aria-selected="false">Dance Room</button>
     <button className="nav-link" id="v-pills-music-tab" data-bs-toggle="pill" data-bs-target="#v-pills-music" type="button" role="tab" aria-controls="v-pills-music" aria-selected="false">Music Room</button>
     <button className="nav-link" id="v-pills-playg-tab" data-bs-toggle="pill" data-bs-target="#v-pills-playg" type="button" role="tab" aria-controls="v-pills-playg" aria-selected="false">Playground</button>
     <button className="nav-link" id="v-pills-radio-tab" data-bs-toggle="pill" data-bs-target="#v-pills-radio" type="button" role="tab" aria-controls="v-pills-radio" aria-selected="false">Radio Room</button>
     <button className="nav-link" id="v-pills-Reception-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Reception" type="button" role="tab" aria-controls="v-pills-Reception" aria-selected="false">Reception</button>
     <button className="nav-link" id="v-pills-sick-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sick" type="button" role="tab" aria-controls="v-pills-sick" aria-selected="false">Sick Room</button>
     <button className="nav-link" id="v-pills-staff-tab" data-bs-toggle="pill" data-bs-target="#v-pills-staff" type="button" role="tab" aria-controls="v-pills-staff" aria-selected="false">Staff Room</button>
     <button className="nav-link" id="v-pills-visitor-tab" data-bs-toggle="pill" data-bs-target="#v-pills-visitor" type="button" role="tab" aria-controls="v-pills-visitor" aria-selected="false">Visitor's Room</button>
     <button className="nav-link" id="v-pills-smart-tab" data-bs-toggle="pill" data-bs-target="#v-pills-smart" type="button" role="tab" aria-controls="v-pills-smart" aria-selected="false">Smart Room</button>
 
   </div>
   <div className="tab-content" id="v-pills-tabContent">
     <div className="tab-pane fade show active" id="v-pills-bio" role="tabpanel" aria-labelledby="v-pills-bio-tab"><h3>Biology Lab</h3>
         <div className="row">
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/biology_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/biology_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-che" role="tabpanel" aria-labelledby="v-pills-che-tab"><h3>Chemistry Lab</h3>
           <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/chemistry_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/chemistry_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-phy" role="tabpanel" aria-labelledby="v-pills-phy-tab"><h3>Physics Lab</h3>
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/physics_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/physics_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-Library" role="tabpanel" aria-labelledby="v-pills-Library-tab"><h3>Library</h3>
       <div className="row">
       <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/library_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/library_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
 
     </div>
     <div className="tab-pane fade" id="v-pills-shooting" role="tabpanel" aria-labelledby="v-pills-shooting-tab"><h3>Shooting Range</h3>
 
    <div className="row">
       <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/shooting_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/shooting_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-basket" role="tabpanel" aria-labelledby="v-pills-basket-tab"><h3>Basketball Court</h3>
 
        <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/basket_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/basket_2.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-acti" role="tabpanel" aria-labelledby="v-pills-acti-tab"><h3>Activity Hall</h3>
     <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/activity_hall_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/activity_hall_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
 
     </div>
     <div className="tab-pane fade" id="v-pills-Building" role="tabpanel" aria-labelledby="v-pills-Building-tab"><h3>Building</h3>
  <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/building_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/building_1.jpg" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
 
     </div>
     <div className="tab-pane fade" id="v-pills-danceroom" role="tabpanel" aria-labelledby="v-pills-danceroom-tab"><h3>Dance Room</h3>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/dance_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/dance_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
 
     </div>
     <div className="tab-pane fade" id="v-pills-music" role="tabpanel" aria-labelledby="v-pills-music-tab"><h3>Music Room</h3>
 
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/music_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/music_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-playg" role="tabpanel" aria-labelledby="v-pills-playg-tab"><h3>Playground</h3>
 
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/playground_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/playground_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-radio" role="tabpanel" aria-labelledby="v-pills-radio-tab"><h3>Radio Room</h3>
 
  <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/radio_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/radio_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-Reception" role="tabpanel" aria-labelledby="v-pills-Reception-tab"><h3>Reception</h3>
 
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/reception_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/reception_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-sick" role="tabpanel" aria-labelledby="v-pills-sick-tab"><h3>Sick Room</h3>
 
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/sick_room_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/sick_room_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-staff" role="tabpanel" aria-labelledby="v-pills-staff-tab"><h3>Staff Room</h3>
 
  <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/staff_room_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/staff_room_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
     <div className="tab-pane fade" id="v-pills-visitor" role="tabpanel" aria-labelledby="v-pills-visitor-tab"><h3>Visitor's Room</h3>
  <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/visitors_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/visitors_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
 
     </div>
     <div className="tab-pane fade" id="v-pills-smart" role="tabpanel" aria-labelledby="v-pills-smart-tab"><h3>Smart Room</h3>
 
 <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/smart_room_1.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-6 fullwidth550"><article><center><img  src="https://webapi.entab.info/api/image/SXSBPB/public/Images/smart_room_2.JPG" alt="St. Xavier’s School Bathinda"/></center></article></div>
        </div>
     </div>
   </div>
 </div>
   <div className="clr"></div>
   </div>
<AboutSidebar/>
</div>
</div>
  <Footer/>
  </>
  )
}

export default Infrastructure
