import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getVideo } from '../../Service/Api';

const JuniorVideo = () => {
    const [selectedOption, setSelectedOption] = useState('option2024');
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [year, setYear] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getVideo();
            galleryData.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
            );
            uniqueYears.sort((a, b) => b - a);
            setYear(uniqueYears);
            console.log(galleryData);
            setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log("events_data", data);
    }, [data]);

    const emptyArray = [
        { title: "Video Title", date: "DD/MM/YYYY" },
        { title: "Video Title", date: "DD/MM/YYYY" },
        { title: "Video Title", date: "DD/MM/YYYY" },
    ];

    useEffect(() => {
        // Filter data based on selected year
        if (selectedYear && selectedYear !== "All") {
            const filteredData = data.filter((item) => {
                const itemYear = new Date(item.date).getFullYear();
                return itemYear === parseInt(selectedYear);
            });
            setFilteredData(filteredData);
        } else {
            // If selected year is "All" or not selected, set filterData to all data
            setFilteredData(data);
        }
    }, [selectedYear, data]); // Added 'data' as a dependency

    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    return (
        <>
            <div className="kids">
                <div className="container juniorcontainer">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <Link to="/JuniorHome">
                                <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <Link to="/JuniorHome">
                                <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" />
                            </Link>
                            <div className="kintergarten_slider_sec_btm_bird">
                                <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/bird.gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="kids-back-sec">
                        <h2>Video</h2>
                        <div className="news-inner">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="clr"></div>
                                    <div className="row">
                                        {filterData && filterData.length > 0 ? (
                                            filterData.filter((item) => item.school === "Junior" || item.school === "Both").map((item, index) => (
                                                <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div className="video-gallery">
                                                        <Link to={item.videoUrl} target="_blank">
                                                            <div>
                                                                <img src={item.attachments.length ? `https://webapi.entab.info/api/image/${item.attachments[0]}` : 'placeholder-image-url'} className="img-fluid" alt="St. Xaviers Bathinda" />
                                                                <h3>{item.title}</h3>
                                                                <p>{item.date}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            emptyArray.map((data, index) => (
                                                <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div className="video-gallery">
                                                        <Link to="#" target="_blank">
                                                            <i className="bi bi-play-circle-fill"></i>
                                                            <div>
                                                                <h3>{data.title}</h3>
                                                                <p>{data.date}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default JuniorVideo;
