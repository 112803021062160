import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import { getHighlightinner } from '../Service/Api';

const Highlights = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Helper function to convert month index to month name
    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const emptyArray = [
        { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates", attachments: [""] },
        { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates", attachments: [""] },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getHighlightinner();

            // Sort data in descending order by date to show the latest data first
            galleryData.sort((a, b) => {
                const dateA = new Date(a.fromDate);
                const dateB = new Date(b.fromDate);
                return dateB - dateA; // Descending order
            });

            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            ).sort((a, b) => b - a);

            setYearRanges(uniqueYears);
        };

        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;

        // Filter by selected year
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    return parseInt(selectedYear) === year;
                }
                return false;
            });
        }

        // Filter by selected month
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                }
                return false;
            });
        }

        // Filter by search query
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
            <Header />
            <div className="inner_slider">
                <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" />
                <div className="row bread_cumb">
                    <h2>Latest @ Xavier's</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Latest @ Xavier's</span></h5>
                </div>
            </div>
            <div className="innerpage">
                <div className="row inner_cont">
                    <div className="col-lg-12">
                        <div className="row tabs-dynamic">
                            <div className="col-md-3">
                                <div className="count-val">
                                    <p>Total Count: {filterData.length}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="month-selection">
                                    <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                        <option value="All">All Years</option>
                                        {yearRanges.map((yr, index) => (
                                            <option key={index} value={yr}>{yr}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="month-selection">
                                    <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                        <option value="All">All Months</option>
                                        {monthsOfYear.map((month, index) => (
                                            <option key={index} value={index + 1}>{month}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="searchBox">
                                    <input 
                                        type="text" 
                                        id="myInput" 
                                        name="name" 
                                        autoComplete="off" 
                                        placeholder="Search Here.." 
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="clr"></div>
                        <div className="row">
                        {
  filterData && filterData.length > 0 ? (
    filterData.filter(item =>
      (item.school === "Senior" || (item.school === "Both" && item.category === "Highlight"))
    ).map((item, index) => (
      <div key={index} className="col-md-6 newsCount">
        <div className="news-blk">
          <div className="news-head">
            <h2>{new Date(item.fromDate).getDate()}<span>{handleMonth(new Date(item.fromDate).getMonth() + 1)}</span></h2>
            <h3>{item.title}</h3>
          </div>
          <div className="news-content">
            <p>{item.description}</p>
          </div>
          <div className="attachment">
            {item.attachments && item.attachments.length > 0 &&
              <a href={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank" rel="noopener noreferrer">
                <i className="bi bi-paperclip"></i>
              </a>
            }
          </div>
        </div>
      </div>
    ))
  ) : (
    emptyArray.map((item, index) => (
      <div key={index} className="col-md-6 newsCount">
        <div className="news-blk">
          <div className="news-head">
            <h2>{item.day}<span>{item.month}</span></h2>
            <h3>{item.title}</h3>
          </div>
          <div className="news-content">
            <p>{item.description}</p>
          </div>
          <div className="attachment">
            <i className="bi bi-paperclip"></i>
          </div>
        </div>
      </div>
    ))
  )
}

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Highlights;
