import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const Reminiscence = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Reminiscence</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Reminiscence</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
<div className="clr"></div>
    <div className="row">
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence1.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence2.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence3.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence4.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence5.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth500"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Reminiscence6.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new"/></center></div>
  </div>  
<div className="clr"></div>
</div>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Reminiscence
