import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { getMedia } from '../Service/Api';

const MediaGallery = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("0");
    const [searchQuery, setSearchQuery] = useState("");
    const [year, setYear] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getMedia();
            setData(galleryData);
           
            const uniqueYears = Array.from(
                new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
            );
            uniqueYears.sort((a, b) => b - a);
            setYear(uniqueYears);
            setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on selected year, month, and search query
        let filteredData = data;

        if (selectedYear && selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                const itemYear = new Date(item.date).getFullYear();
                return itemYear === parseInt(selectedYear);
            });
        }

        if (selectedMonth && selectedMonth !== "0") {
            filteredData = filteredData.filter((item) => {
                const itemMonth = new Date(item.date).getMonth() + 1;
                return itemMonth === parseInt(selectedMonth);
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) => 
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const emptyArray = [
        { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/about1.jpg" },
        { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/about1.jpg" },
        { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/about1.jpg" },
        { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/about1.jpg" },
    ];

    const handleModal = (id) => {
        navigate(`/SubGallery?id=${id}`); // Pass the ID as a query parameter in the URL
    }

    return (
        <>
            <Header />
            <div className="inner_slider">
                <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" />
                <div className="row bread_cumb">
                    <h2>Media Gallery</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery  <i className="bi bi-arrow-right"></i>  Media Gallery</span></h5>
                </div>
            </div>
            <div className="innerpage media_sec inner_cont">
                <div className="row tabs-dynamic">
                    <div className="col-md-3">
                        <div className="count-val">
                            <p>Total Count: {filterData.length}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="month-selection">
                            <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                <option value="All">All Years</option>
                                {year.map((yr, index) => (
                                    <option key={index} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="month-selection">
                            <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                <option value="0">All Months</option>
                                {monthsOfYear.map((month, index) => (
                                    <option key={index} value={index + 1}>{month}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="searchBox">
                            <input 
                                type="text" 
                                id="myInput" 
                                name="name" 
                                autoComplete="off" 
                                placeholder="Search Here.." 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="clr"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            {filterData.length > 0 ? filterData.map((item, index) => (
                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <article>
                                        <center>
                                            <SlideshowLightbox><img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} alt="St. Xavier’s School Bathinda" className="img-fluid" /></SlideshowLightbox>
                                        </center>
                                        <h4 title="Preparing students to don the mantle of leadership">{item.title}</h4>
                                        <p><strong>Newspaper :</strong> {item.newspaper} </p>
                                        <p><strong>Published On :</strong> {item.date}</p>
                                        <p className="media_des" title="title"><strong>Description :</strong> {item.description}</p>
                                    </article>
                                </div>
                            )) : (
                                emptyArray.map((data, index) => (
                                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                        <article>
                                            <center>
                                                <SlideshowLightbox><img src={data.attachments || 'placeholder-image-url'} alt="St. Xavier’s School Bathinda" className="img-fluid" /></SlideshowLightbox>
                                            </center>
                                            <h4 title="Preparing students to don the mantle of leadership">{data.title}</h4>
                                            <p><strong>Newspaper :</strong> Name </p>
                                            <p><strong>Published On :</strong> DD.MM.YYYY</p>
                                            <p className="media_des" title="title"><strong>Description :</strong> {data.description}</p>
                                        </article>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MediaGallery;
