import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'

const Attendance = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Attendance Rules</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Attendance Rules</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul>
   <li>Pupils must be punctual at the beginning of the day, for all subjects classes and activities.</li>
   <li>Attendance for morning assembly is compulsory. At the end of the assembly, students shall proceed in silence to their classrooms.</li>
   <li>For every absence, a written statement, signed by the Parent/Guardian is required on the day the pupil returns. If a student is absent for more than 3 days for reasons of ill health a certificate from a doctor is required stating illness and fitness.</li>
   <li>A student who arrives late will be required to bring an explanatory note from Parent/Guardian.</li>
   <li>All explanatory notes for absence/late arrival should be handed over to the class teacher. No one who is 5 times late or has been absent for 3 days and over will be admitted to the class, without the permission of the Principal.</li>
   <li>During the vacation period, pupils are not granted leave. Prior permission should be sought for absence from the school for any reason or planned in advance.</li>
   <li>Students who are regularly sick and fall short of the required 75% of attendance may be asked to leave the school on grounds of physical unfitness.</li>
   <li>Students will not be allowed to leave school early unless Parents/Guardian present a written note to the office. Explicit permission by the Principal is necessary.</li>
   <li>In case of infectious disease, the parents are required to submit a medical certificate to the effect that child is fit to rejoin.</li>
   <li>Attendance on the first day of the school, after vacations is absolutely essential. Pupils who do not join school on the 1 st day will be marked absent for next 5 days.</li>
   <li>Any unscheduled holidays may be made good by the school without prior intimation to Parents/Guardian.</li>
   </ul>
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Attendance.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/about.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
       </div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default Attendance
