import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { getEvents } from '../Service/Api';
const Events = () => {
    var settings = {  
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        initialSlide: 0, 
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
          
            {
              breakpoint: 1300,
              settings: {
                slidesToShow:2, 
              }
            },
            {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 2, 
                }
              },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2, 
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow:1, 
              }
            }
          ]
      };

      
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://webapi.entab.info/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://webapi.entab.info/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://webapi.entab.info/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" }
  ];

  return (
     <> 
     <div className="row">
     <Slider {...settings}> 
{data?.length > 0 ? data?.filter((item) => item.school === "Both" || item.school === "Senior").map((item, index) => (
          
     <div key={index} className="eventcard">
      <div className="card">
         <div className="backgroundEffect"></div>
         <div className="pic"><img src={`https://webapi.entab.info/api/image/${item?.images[0]}`} className="card-img-top" alt="St. Xaviers Bathinda" /></div>
           <div className="date"> 
               <Link target="_blank" to="#"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/attachment-icon.png" alt="St. Xaviers Bathinda" /></Link> 
           </div>
           <div className="card-body"><h5 className="card-title">{item.title}</h5><p className="card-text">{item.description}</p></div>
              <div className="card-footer"><div className="d-flex align-items-center justify-content-center foot"><p className="admin">{item.date} | {item.venue} | {item.time}  </p></div></div></div>
            </div>
             )) : emptyArray.map((item, index) => (
              <div key={index} className="eventcard">
              <div className="card">
                 <div className="backgroundEffect"></div>
                 <div className="pic"><img src={item.images} className="card-img-top" alt="St. Xaviers Bathinda" /></div>
                   <div className="date"> 
                       <Link target="_blank" to="#"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/attachment-icon.png" alt="St. Xaviers Bathinda" /></Link> 
                   </div>
                   <div className="card-body"><h5 className="card-title">{item.title}</h5><p className="card-text">{item.description}</p></div>
                      <div className="card-footer"><div className="d-flex align-items-center justify-content-center foot"><p className="admin">{item.date} | {item.venue} | {item.time}  </p></div></div></div>
                    </div>
            ))}
     
     {/* <div className="eventcard"><div className="card">
         <div className="backgroundEffect"></div>
         <div className="pic"><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/events.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
           <div className="date"> 
               <Link target="_blank" to="#"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/attachment-icon.png" alt="St. Xaviers Bathinda" /></Link> 
           </div>
           <div className="card-body"><h5 className="card-title">Event Name - 1</h5><p className="card-text">Coming Soon...</p></div>
              <div className="card-footer"><div className="d-flex align-items-center justify-content-center foot"><p className="admin">dd.mm.yyyy | School | hh:mm   </p></div></div></div>
            </div>
            <div className="eventcard"><div className="card">
            <div className="backgroundEffect"></div>
            <div className="pic"><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/events.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
              <div className="date"> 
                  <Link target="_blank" to="#"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/attachment-icon.png" alt="St. Xaviers Bathinda" /></Link> 
              </div>
              <div className="card-body"><h5 className="card-title">Event Name - 1</h5><p className="card-text">Coming Soon...</p></div>
                 <div className="card-footer"><div className="d-flex align-items-center justify-content-center foot"><p className="admin">dd.mm.yyyy | School | hh:mm   </p></div></div></div>
               </div> */}
       </Slider>
     </div>
     </>
  )
}

export default Events
