import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'

const AssessmentRules = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Assessment Rules</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Assessment Rules</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <p>1. Promotion from one standard to another is made at the end of the School. academic year, on the basis of the child's performance in the whole year's work. The aggregate of the whole year is taken into consideration for promotion as well as for grading. It also depends upon :</p>
   <ul>
   <li>Regularity in attendance.</li>
   <li>Regularity and quality of day - to - day participation in the class.</li>
   <li>Opinion of teachers in the class.</li>
   <li>Written work and projects assigned by the subject teachers.</li>
   <li>Office records.</li></ul>
   <p>2. To be eligible for promotion, the child must secure at least D grade in the aggregate in each subject in the primary section and D grade in the high school in each subject.</p>
   <p>3. KG to X</p>
   <div className="innertable">
   <table width="100%">
                   <tbody>
   <tr>
       <td>Marks Range</td>
       <td>Grade</td>
       <td>Grade Point</td>
     </tr>
     <tr>
       <td>91-100</td>
       <td>A1</td>
       <td>10.0</td>
     </tr>
     <tr>
       <td>81-90</td>
       <td>A 2</td>
       <td>9.0</td>
     </tr>
     <tr>
       <td>71-80</td>
       <td>B1</td>
       <td>8.0</td>
     </tr>
     <tr>
       <td>61-70</td>
       <td>B2</td>
       <td>7.0</td>
     </tr>
     <tr>
       <td>51-60</td>
       <td>C 1</td>
       <td>6.0</td>
     </tr>
     <tr>
       <td>41-50</td>
       <td>C2</td>
       <td>5.0</td>
     </tr>
     <tr>
       <td>33-40</td>
       <td>D</td>
       <td>4.0</td>
     </tr>
     <tr>
       <td>21-32</td>
       <td>E1</td>
       <td>-</td>
     </tr>
     <tr>
       <td>00-20</td>
       <td>E2</td>
       <td>-</td>
     </tr></tbody></table>
   </div>
   <p>4. In case, a pupil is absent in any examination, he/she will not be re-examined under any circumstances. In such cases, promotion of the deserving pupils will be decided on the strength of the examinations they have already appeared for the whole year's performance.</p>
   <p>5. Absence from one or more subjects involves a loss of marks for the subjects and excludes the pupils from being reckoned in order of merit in the examination and from a merit certificate.</p>
   <p>6. The answer - script of the Final Exam, will neither be shown to students nor to parents. However, in special cases, the Principal may permit to review the final exam answer - script on payment of Rs. 100/- per subject in the school office along with the written application, within three days of the declaration of results. Subsequently, no such plea shall be entertained. The parent/child shall not be associated in the process or review and the decision of the Principal in this matter is final and binding on all the parties. The Principal is competent to adopt any procedure he deems fit. In any case the answer - script will not be issued / shown to the applicants.</p>
   
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/assessment.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/assessment1.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
       </div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default AssessmentRules
