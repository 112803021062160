import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
      <>  
<div className="footer-sec p-4 span3 wow bounceInDown center" data-wow-delay="1s">
<div className="row">
<div className="col-12 footer-btm-link">
    <ul>
    <li><Link to="/sitemap.html">Sitemap </Link>    </li>
    <li><Link to="/Reminiscence">Reminiscence </Link>    </li>
    <li><Link to="/Career">Career </Link>    </li>
    </ul>
    </div> 
  <div className="col-12 col-sm-6 col-lg-3">
    <div className="card mb-3">
      <div className="row g-0">
        <div className="col-sm-4 col-md-4"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/location.png" className="img-fluid rounded float-right" alt="St. Xaviers Bathinda" /> </div>
        <div className="col-sm-8 col-md-8">
          <div className="card-body">
          <Link to="https://goo.gl/maps/oXckzJUaUXFL79Md6" target="_blank">
            <h5>Location</h5>
            <p>Model Town Phase 2, Bathinda,<br /> Punjab, India, 151001</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-lg-3">
    <div className="card mb-3">
      <div className="row g-0">
        <div className="col-sm-4 col-md-4"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/phone-call.png" className="img-fluid rounded-start" alt="St. Xaviers Bathinda" /> </div>
        <div className="col-sm-8 col-md-8">
          <div className="card-body">
            <h5>Phone</h5>
            <p><Link to="tel:01642211800">0164 2211800</Link></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-lg-3">
    <div className="card mb-3">
      <div className="row g-0">
        <div className="col-sm-4 col-md-4"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/open-email.png" className="img-fluid rounded-start" alt="St. Xaviers Bathinda" /> </div>
        <div className="col-sm-8 col-md-8">
          <div className="card-body">
            <h5>Mail</h5>
            <p><Link to="mailto:xavierbathinda@gmail.com">xavierbathinda@gmail.com</Link></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="col-12 col-sm-6 col-lg-3">
  <div className="card">
<h5>Download Mobile App</h5>
    <ul className="list-inline">
            <li className="list-inline-item"><Link to="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target="_blank"><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/home-app-google-play.png" className="img-fluid app-icon" alt="St. Xaviers Bathinda" /></Link></li>
            <li className="list-inline-item"><Link to="https://apps.apple.com/in/app/campuscare/id1034721587" target="_blank"> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/home-app-store.png" className="img-fluid app-icon" alt="St. Xaviers Bathinda" /></Link></li>
         </ul>
         </div> 
  </div>      
        
        


  <div className="clearfix"></div>
  <p>© Copyright 2020 - 2022 By St. Xavier’s School, Bathinda |  Created By <Link className="link" href="https://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </Link></p>
</div>

</div>
      </>
  )
}

export default Footer
