import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import BulletinSidebar from'./BulletinSidebar'
import { Link } from 'react-router-dom'
const VirtualTour = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Virtual Tour</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery <i className="bi bi-arrow-right"></i>  Virtual Tour</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 circular_sec">
<img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/coming-soon.jpg" alt="St. Xaviers Bathinda" className="coming_soon img-fluid"/>
        </div>
<BulletinSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default VirtualTour
