import React, { useEffect, useState } from 'react';
import { getHighlight } from '../../Service/Api';

const Highlights = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const highlightData = await getHighlight();
        setData(highlightData);
      } catch (error) {
        console.error('Failed to fetch data', error);
        setData([{ description: "Stay tuned for latest updates" }]); // Fallback data
      }
    };
    fetchData();
  }, []);

  return (
    <div className="highlight_sec">
      <h4>Highlights:</h4>
      <marquee 
        onMouseOver={(event) => event.currentTarget.stop()} 
        onMouseOut={(event) => event.currentTarget.start()}
      >
        <p>
          {data.length > 0 ? (
            data.filter(item => item.school === "Junior" || item.school === "Both" && item.category === "Highlight")
              .map((item, index) => (
                <a
                  style={{ color: "white" }}
                  href={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  {item.description} 
                  {item.attachments.length > 0 && <i style={{ color: "white" }} className="bi bi-paperclip"></i>}
                  {" | "}
                </a>
              ))
          ) : (
            <span>Stay tuned for latest updates</span>
          )}
        </p>
      </marquee>
      <div className="clr"></div>
    </div>
  );
}

export default Highlights;
