import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getGallery } from '../Service/Api';
const HomeGallery = () => {
  
const [data, setData] = useState([]);
useEffect(() => {
  const fetchData = async () => {
    const topperData = await getGallery();
    setData(topperData);
  };
  fetchData();
}, []);

useEffect(() => {
  console.log("events_data", data[0]);
}, [data]);

const emptyArray = [
  { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description : "Stay tuned for latest updates"},   
  { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description : "Stay tuned for latest updates"},   
 { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description : "Stay tuned for latest updates"},   
];


  return (
    <>
    {data.length > 0 ? (
      data
        .filter(item => item.school === "Senior" || item.school === "Both" && item.category === "PhotoGallery")
        .slice(0, 3)
        .map((item, index) => (
          <div className="col-xl-3 col-lg-6 col-sm-6 col-12" key={index}>
            <article>
              <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt={item.title}/>
              <h3>{item.title}</h3>
              <div className="link">
                <Link to="/PhotoGallery"><i className="bi bi-arrow-90deg-right"></i></Link>
              </div>
            </article>
          </div>
        ))
    ) : (
      emptyArray.map((data, index) => (
        <div className="col-xl-3 col-lg-6 col-sm-6 col-12" key={index}>
          <article>
            <img src={data.attachments} className="img-fluid" alt={data.title}/>
            <h3>{data.title}</h3>
            <div className="link">
              <Link to="/PhotoGallery"><i className="bi bi-arrow-90deg-right"></i></Link>
            </div>
          </article>
        </div>
      ))
    )}
  </>
  
  )
}

export default HomeGallery
