import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import { getEventsinner } from '../Service/Api';

const Events = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner();
            setData(galleryData);

            const uniqueYears = Array.from(new Set(galleryData.map(item => new Date(item.date).getFullYear())));
            uniqueYears.sort((a, b) => b - a);
            setYearRanges(uniqueYears);

            const uniqueMonths = Array.from(new Set(galleryData.map(item => new Date(item.date).getMonth())));
            uniqueMonths.sort((a, b) => a - b);
            setMonthOptions(["All", ...uniqueMonths.map(month => monthNames[month])]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;

        if (selectedYear !== "All") {
            filteredData = filteredData.filter(item => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                }
                return false;
            });
        }

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter(item => {
                if (item.date) {
                    const month = new Date(item.date).getMonth();
                    return monthNames[month] === selectedMonth;
                }
                return false;
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter(item =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const emptyArray = [
        { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
        { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
        { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
        { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    return (
        <>
            <Header />
            <div className="inner_slider">
                <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" />
                <div className="row bread_cumb">
                    <h2>Events</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Events</span></h5>
                </div>
            </div>
            <div className="innerpage">
                <div className="inner_cont">
                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                    <option value="All">All Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                    <option value="All">All Months</option>
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Search Here.."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2024 showYear">
                        {filterData.length > 0 ? (
                            filterData?.filter((item) => item.school === "Both" || item.school === "Senior").map((item, index) => (
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" key={index}>
                                    <div className="newsbox">
                                        <div className="newsboximg">
                                            <img src={`https://webapi.entab.info/api/image/${item?.images[0]}`} className="img-fluid" alt="" />
                                        </div>
                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                                <span><b>Location: </b> {item.venue}</span>
                                            </div>
                                            <div className="newsdate">
                                                <span><i className="bi bi-calendar"></i> {formatDate(item.date)}</span>
                                                <span><i className="bi bi-alarm"></i> {item.time}</span>
                                                <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            emptyArray.map((data, index) => (
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" key={index}>
                                    <div className="newsbox">
                                        <div className="newsboximg">
                                            <img src={data.attachments} className="img-fluid" alt="" />
                                        </div>
                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{data.title}</h5>
                                                <p>{data.description}</p>
                                                <span><b>Location: </b> {data.venue}</span>
                                            </div>
                                            <div className="newsdate">
                                                <span><i className="bi bi-calendar"></i> {data.date}</span>
                                                <span><i className="bi bi-alarm"></i> {data.time}</span>
                                                <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Events;
