import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const OurStrength = () => {
   return (
    <>
<Header/>   <div className="inner_slider">
   <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Our Strength</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Our Strength</span></h5>
   </div>
   
   </div>
   <div className="innerpage">
<div className="row inner_cont">
  <div className="col-lg-9 col-md-8 col-sm-12 col-12 our_strength">
  <div className="row">
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/lkg.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>191</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/ukg.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>213</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/1.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>214</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/2.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>223</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/3.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>222</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/4.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>269</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/5.png"alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>324</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/6.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>314</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/7.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>318</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/8.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>322</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/9.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>295</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/10.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>282</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/11.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>184</span></h3></article></div>
<div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth"><article><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/12.png" alt="St. Xavier’s School Bathinda"/><h3>Strength : <span>189</span></h3></article></div>
   
   </div> 
<div className="clr"></div>
</div>

<AboutSidebar/>
</div>
</div>
 <Footer/>
  </>
  )
}

export default OurStrength
