import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
const Career = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Career</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Career</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
   
<h3 className="text-center"><Link className="career" to="https://forms.gle/tixsXTE1TEfFR4SC6" target="_blank">Click Here to Apply</Link></h3>
</div>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Career
