import React from 'react'
import { Link } from 'react-router-dom'
const Erplinks = () => {
  return (
     <>
     <div className="erp_links"> <Link to="https://xavierbathinda.campuscare.info/" target="_blank"><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Campulogo.png" alt="St. Xaviers Bathinda" /></Link>
     </div>
     </>
  )
}

export default Erplinks
